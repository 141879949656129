@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");

body.cdt {
  font-family: "Roboto", sans-serif;
  line-height: 1.3em;
  font-weight: 300;
  font-size: 14px;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
	vertical-align: middle;
}

.table-hover tbody tr:hover,
table {
  color: $text-color;
  font-size: 14px;
  font-weight: 300;
}

.cdt .dropzone {
  min-height: unset;
  padding: 10px 10px;
  border: 1px dashed #878786;
}

.cdt .Toastify__toast--success {
	background: $navy;

}

.cdt .Toastify__toast {
  color: #000000;
  font-weight: bolder;
}